<template>
  <div class="home">
  	<Nav />
  	<Breadcrumbs :items="crumbs" />
    <div class="home__main">
    	<transition name="fade">
    		<Loader v-if="!products" />
    	</transition>
    	<div class="ml-3 mb-3 flex">
    		<button class="btn btn__large btn__toggle" v-bind:class="{ btn__dark: surgical }" @click="surgical = true">Surgical</button>
    		<button class="btn btn__large btn__toggle ml-3" @click="surgical = false"  v-bind:class="{ btn__dark: !surgical }">Non-Surgical</button>
    	</div>
    	<transition name="fadeStop">
	    	<ProductsList :products="surgicalProducts" v-if="surgical == true" />
	    </transition>
	    <transition name="fadeStop">
	    	<ProductsList :products="nonSurgicalProducts" v-if="surgical == false" />
	    </transition>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProductsList from '@/components/ProductsList.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'home',
  data: () => ({
    surgical: false,
  }),
  computed: {
    ...mapState(['currentUser', 'userProfile', 'products']),
    nonSurgicalProducts() {
    	return this.products.filter(product => {
				return (product.category != "Surgical N95 Bulk" && product.visible == true)
			})
    },
    surgicalProducts() {
    	return this.products.filter(product => {
				return (product.category == "Surgical N95 Bulk" && product.visible == true)
			})
    },
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Catalog", to: false}
      crumbsArray.push(step1)
      return crumbsArray
    },
  },
  components: {
  	Nav,
  	ProductsList,
  	Breadcrumbs,
  	Footer,
  	Loader
  }
}
</script>
